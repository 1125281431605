(function($) {
	"use strict";
	$(function() {

		// Read a page's GET URL variables and return them as an associative array.
		function getUrlVars()
		{
			var vars = [], hash;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for(var i = 0; i < hashes.length; i++)
			{
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		}

		$('#admin-filter').submit(function (e) {
			e.preventDefault();

			const order_by = $('#order_by').val();
			const order_dir = $('#order_dir').val();
			let page = $('#page').val();

			let params = '';

			if (order_by) {
				if(params == '') {
					params = '?order_by=' + order_by;
				} else {
					params += '&order_by=' + order_by;
				}
			}

			if (order_dir) {
				if(params == '') {
					params = '?order_dir=' + order_dir;
				} else {
					params += '&order_dir=' + order_dir;
				}
			}

			if (page) {
				if(params == '') {
					params = '?page=' + page;
				} else {
					params += '&page=' + page;
				}
			}

			const url = $('#admin-filter').attr('action') + params;
			const ajaxUrl = $('#admin-filter').attr('action') + 'Filter' + params;

			history.pushState({ url: url, title: url }, url, url);

			$.ajax({
				url: ajaxUrl,
				method: 'GET',
				dataType: 'json',
				success(response) {
					$('.ajax-content .table').remove();
					$('.ajax-content .pagination').remove();

					if (response.adminContentHTML != null) {
						$('.ajax-content').html(response.adminContentHTML);

						// no content and current page is not 1 -> return to first page
						if (response.adminContentHTML == '' && getUrlVars()['page'] && getUrlVars()['page'] != 1) {
							$('#page').val(1);
							$('#admin-filter').submit();
						}
					}

					if($('.pagination').length) {

						$('.page-item:not(.disabled) .page-link').each(function (e) {
							let href = $(this).attr('href');
							$(this).attr('href', href.replace('Filter',''));
							$(this).attr('href', decodeURIComponent($(this).attr('href')));
						})
					}
				},
				error(response) {
					console.log(response);
				}
			});
		});

		$(document).on('click', '.ajax-content .page-link', function (e) {
			e.preventDefault();
			var currentPage = parseInt($('#page').val());

			if ($(this).attr('rel') == 'next'){
				$('#page').val(currentPage + 1);
			} else if ($(this).attr('rel') == 'prev') {
				$('#page').val(currentPage - 1);
			} else {
				$('#page').val($(this).text());
			}

			$('#admin-filter').submit();
		});

		$(document).on('click', '#songs_statistics_table th', function () {
			var $this = $(this);
			if ($('#order_by').val() == $this.eq(0).data('filter')) {
				if ($('#order_dir').val() == 'asc') {
					$('#order_dir').val('desc')
				} else {
					$('#order_dir').val('asc');
				}
			} else {
				if ($this.eq(0).data('filter') == 'total' || $this.eq(0).data('filter') == 'last_played') {
					$('#order_dir').val('desc');
				} else {
					$('#order_dir').val('asc');
				}
				$('#page').val(1);
			}
			$('#order_by').val($this.eq(0).data('filter'));
			$('#admin-filter').submit();
		});

		if ($('#admin-filter').length) {
			$('#admin-filter').submit();
		}
	});
})(jQuery);