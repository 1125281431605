(function($) {
	"use strict";
	$(function() {

		$('#select_for_song').on('change', function () {
			$('#addSongToEvent').trigger('submit');
		});

		$('#select_for_news').on('change', function () {
			$('#addNewsToEvent').trigger('submit');
		});

		$('#book_nmb').on('keydown', function (e) {
			// enter, space, vessző, pont / kettőspont esetén tovább a vers bevitelhez
			if (e.keyCode == 13 || e.keyCode == 32 || e.keyCode == 188 || e.keyCode == 190) {
				e.preventDefault();
				$('#verse_nmb').focus();
			}
		});

		$('#addSongToEvent').submit(function(e){
			var $form = $('#addSongToEvent');
			e.preventDefault();

			$.ajax({
				url: $form.attr('action'),
				method: 'POST',
				datatype: 'JSON',
				data: $form.serialize(),
				success: function(response){
					if (response.status == 'ok') {
						$('.sortable-song-list').append('<div class="draggable-song ui-sortable-handle" data-pivot_id="' + response.pivot_id + '">'+
							'<div class="song-id">' + response.song_id + '</div>' +
							'<div class="song-title">' + response.displayTitle + '</div>' +
						'<button type="submit" class="delete-song-from-event icon-delete fa fa-times"></button>' +
							'</div>');
						$('.sortable-song-list').sortable('refresh');
						$('.sortable-song-list').show(200);
						toastr.success(response.message);
						//clear song select
						$('#select_for_song')[0].selectize.clear(true);
					}
					else {
						toastr.error(response.message);
					}
				},
				error: function(response){
					if (response.status === 422) {
						var response = response.responseJSON;
						for(var k in response.errors) {
							$('#'+k).closest('.form-group').addClass('error').find('p.error-response').html(response.errors[k][0]);
						}
						return;
					}

					if (response.message) {
						toastr.error(response.message);
					}
					else {
						toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
					}
				}
			});

			return false;
		});

		$('.sortable-song-list').sortable({
			placeholder: "ui-state-highlight",
			'revert': 300,	//animation duration to text back to position (false -> animation off)
			update: function(event, ui) {
				var pivot_ids = $('.draggable-song').map(function(){
					return $(this).data("pivot_id");
				}).get();

				var $form = $('#SongsInEvent');
				var serialized_data = $form.serialize();
				serialized_data += "&pivot_ids=" + pivot_ids;

				$.ajax({
					url: $form.attr('action'),
					method: 'POST',
					datatype: 'JSON',
					data: serialized_data,
					success: function(response){
						if (response.status == 'ok') {
							toastr.success(response.message);
						}
						else {
							toastr.error(response.message);
						}
					},
					error: function(response){
						if (response.status === 422) {
							var response = response.responseJSON;
							for(var k in response.errors){
								$('#'+k).closest('.form-group').addClass('error').find('p.error-response').html(response.errors[k][0]);
							}
							return;
						}

						if (response.message) {
							toastr.error(response.message);
						}
						else {
							toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
						}
					}
				});
			}
		});

		$('.sortable-song-list').disableSelection();

		$(document).on('click', '.delete-song-from-event', function(e){
			var $this = $(this);
			var $form = $('#SongsInEvent');
			e.preventDefault();

			var order = ($('.draggable-song').index($this.parent()))+1;
			var serialized_data = $form.serialize();
			serialized_data += "&order_id=" + order;

			$.ajax({
				url: $form.attr('action'),
				method: 'POST',
				datatype: 'JSON',
				data: serialized_data,
				success: function(response){
					if (response.status == 'ok') {
						$this.parent().remove();
						if (($('.sortable-song-list').text()).replace(/\s/g, '').length == 0) {
							$('.sortable-song-list').hide(200);
						}
						toastr.success(response.message);
					}
					else {
						toastr.error(response.message);
					}
				},
				error: function(response){
					if (response.status === 422) {
						var response = response.responseJSON;
						for(var k in response.errors){
							$('#'+k).closest('.form-group').addClass('error').find('p.error-response').html(response.errors[k][0]);
						}
						return;
					}

					if (response.message) {
						toastr.error(response.message);
					}
					else {
						toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
					}
				}
			});

			return false;
		});

		$('#abbrevation_id').on('change', function () {
			$('#book_nmb').focus();
		});

		$('#addVerseToEvent').submit(function(e){
			var $form = $('#addVerseToEvent');
			e.preventDefault();

			$.ajax({
				url: $form.attr('action'),
				method: 'POST',
				datatype: 'JSON',
				data: $form.serialize(),
				success: function(response){
					if (response.status == 'ok') {
						$('.sortable-verse-list').append('<div class="draggable-verse ui-sortable-handle" data-pivot_id="' + response.pivot_id + '">'+
							'<div class="verse-title" id="' + response.verse.id + '">' + response.abbrevation + ' ' + response.verse.book_nmb + ':' + response.verse.verse_nmb + '</div>' +
							'<button type="submit" class="delete-verse-from-event icon-delete fa fa-times"></button>' +
							'</div>');
						$('.sortable-verse-list').sortable('refresh');
						$('.sortable-verse-list').show(200);
						toastr.success(response.message);
						$('#addVerseToEvent').trigger("reset");
						// clear abbrevation select
						$('#abbrevation_id')[0].selectize.clear(true);
						$('#book_nmb').siblings('.clear-button').toggleClass('d-none');
						$('#verse_nmb').siblings('.clear-button').toggleClass('d-none');
						$('#abbrevation_id-selectized').focus();
					}
					else {
						toastr.error(response.message);
					}
				},
				error: function(response){
					if (response.status === 422) {
						var response = response.responseJSON;
						for(var k in response.errors){
							$('#'+k).closest('.form-group').addClass('error').find('p.error-response').html(response.errors[k][0]);
						}
						return;
					}

					if (response.message) {
						toastr.error(response.message);
					}
					else {
						toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
					}
				}
			});

			return false;
		});

		$('.sortable-verse-list').sortable({
			placeholder: "ui-state-highlight",
			'revert': 300,	//animation duration to text back to position (false -> animation off)
			update: function(event, ui) {
				var pivot_ids = $('.draggable-verse').map(function(){
					return $(this).data("pivot_id");
				}).get();

				var $form = $('#VersesInEvent');
				var serialized_data = $form.serialize();
				serialized_data += "&pivot_ids=" + pivot_ids;

				$.ajax({
					url: $form.attr('action'),
					method: 'POST',
					datatype: 'JSON',
					data: serialized_data,
					success: function(response){
						if (response.status == 'ok') {
							toastr.success(response.message);
						}
						else {
							toastr.error(response.message);
						}
					},
					error: function(response){
						if (response.status === 422) {
							var response = response.responseJSON;
							for(var k in response.errors){
								$('#'+k).closest('.form-group').addClass('error').find('p.error-response').html(response.errors[k][0]);
							}
							return;
						}

						if (response.message) {
							toastr.error(response.message);
						}
						else {
							toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
						}
					}
				});
			}
		});

		$('.sortable-verse-list').disableSelection();

		$(document).on('click', '.delete-verse-from-event', function(e){
			var $this = $(this);
			var $form = $('#VersesInEvent');
			e.preventDefault();

			var order = ($('.draggable-verse').index($this.parent()))+1;
			var serialized_data = $form.serialize();
			serialized_data += "&order_id=" + order;

			$.ajax({
				url: $form.attr('action'),
				method: 'POST',
				datatype: 'JSON',
				data: serialized_data,
				success: function(response){
					if (response.status == 'ok') {
						$this.parent().remove();
						if (($('.sortable-verse-list').text()).replace(/\s/g, '').length == 0) {
							$('.sortable-verse-list').hide(200);
						}
						toastr.success(response.message);
					}
					else {
						toastr.error(response.message);
					}
				},
				error: function(response){
					if (response.status === 422) {
						var response = response.responseJSON;
						for(var k in response.errors){
							$('#'+k).closest('.form-group').addClass('error').find('p.error-response').html(response.errors[k][0]);
						}
						return;
					}

					if (response.message) {
						toastr.error(response.message);
					}
					else {
						toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
					}
				}
			});

			return false;
		});

		if ($('.js-delete-event').length) {
			$('.js-delete-event').click(function(e){
				var $this = $(this);
				e.preventDefault();

				bootbox.confirm({
					message: 'Biztosan kitörtöd a vetítést?',
					buttons: {
						confirm: {
							label: 'Igen',
							className: 'btn btn-danger'
						},
						cancel: {
							label: 'Mégsem',
							className: 'btn'
						}
					},
					callback: function(r){
						if (r === true) {
							$('#deleteEvent').submit();
						}
					}
				});
			});
		}

		$("#select_for_worship_leader").change(function() {
			var $this = $(this);
			$('input[name="worship_leader"]').val($this.val()).trigger('propertychange');
		});

		$("#select_for_teacher").change(function() {
			var $this = $(this);
			$('input[name="teacher"]').val($this.val()).trigger('propertychange');
		});

		$('#addNewsToEvent').submit(function(e){
			var $form = $('#addNewsToEvent');
			e.preventDefault();

			$.ajax({
				url: $form.attr('action'),
				method: 'POST',
				datatype: 'JSON',
				data: $form.serialize(),
				success: function(response){
					if (response.status == 'ok') {
						$('.sortable-news-list').append('<div class="draggable-news ui-sortable-handle" data-news_id="' + response.news_id + '" data-pivot_id="' + response.pivot_id + '">'+
							'<div class="news-title">' + response.displayTitle + '</div>' +
							'<button type="button" data-news-edit-url="' + response.updateUrl + '" class="edit-news icon-edit fa fa-edit"></button>' +
							'<button type="submit" class="delete-news-from-event icon-delete fa fa-times"></button>' +
							'</div>');
						$('.sortable-news-list').sortable('refresh');
						$('.sortable-news-list').show(200);
						toastr.success(response.message);
						//clear news select
						$('#select_for_news')[0].selectize.clear(true);
					}
					else {
						toastr.error(response.message);
					}
				},
				error: function(response){
					if (response.status === 422) {
						var response = response.responseJSON;
						for(var k in response.errors) {
							$('#'+k).closest('.form-group').addClass('error').find('p.error-response').html(response.errors[k][0]);
						}
						return;
					}

					if (response.message) {
						toastr.error(response.message);
					}
					else {
						toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
					}
				}
			});

			return false;
		});

		$('.sortable-news-list').sortable({
			placeholder: "ui-state-highlight",
			'revert': 300,	//animation duration to text back to position (false -> animation off)
			update: function(event, ui) {
				var pivot_ids = $('.draggable-news').map(function(){
					return $(this).data("pivot_id");
				}).get();

				var $form = $('#NewsInEvent');
				var serialized_data = $form.serialize();
				serialized_data += "&pivot_ids=" + pivot_ids;

				$.ajax({
					url: $form.attr('action'),
					method: 'POST',
					datatype: 'JSON',
					data: serialized_data,
					success: function(response){
						if (response.status == 'ok') {
							toastr.success(response.message);
						}
						else {
							toastr.error(response.message);
						}
					},
					error: function(response){
						if (response.status === 422) {
							var response = response.responseJSON;
							for(var k in response.errors){
								$('#'+k).closest('.form-group').addClass('error').find('p.error-response').html(response.errors[k][0]);
							}
							return;
						}

						if (response.message) {
							toastr.error(response.message);
						}
						else {
							toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
						}
					}
				});
			}
		});

		$('.sortable-news-list').disableSelection();

		$('.create-new-news-button').on('click',  function(e) {
			var $this = $(this);
			var $formUrl = $(this).data('news-create-url');

			$.ajax({
				url: $formUrl,
				method: 'GET',
				dataType: 'json',
				success(response) {
					if (response.newsModalHTML != null) {
						$('body').append(response.newsModalHTML);
						$('#createNewsModal').modal('show');
					}
				},
				error(response) {
					if (response.message) {
						toastr.error(response.message);
					}
					else {
						toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
					}
				}
			});
		});

		$(document).on('click', '.edit-news', function(e){
			var $this = $(this);
			var $formUrl = $(this).data('news-edit-url');

			$.ajax({
				url: $formUrl,
				method: 'GET',
				dataType: 'json',
				success(response) {
					if (response.newsModalHTML != null) {
						$('body').append(response.newsModalHTML);
						$('#createNewsModal').modal('show');
					}
				},
				error(response) {
					if (response.message) {
						toastr.error(response.message);
					}
					else {
						toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
					}
				}
			});
		});

		$(document).on('click', '.delete-news-from-event', function(e){
			var $this = $(this);
			var $form = $('#NewsInEvent');
			e.preventDefault();

			var order = ($('.draggable-news').index($this.parent()))+1;
			var serialized_data = $form.serialize();
			serialized_data += "&order_id=" + order;

			$.ajax({
				url: $form.attr('action'),
				method: 'POST',
				datatype: 'JSON',
				data: serialized_data,
				success: function(response){
					if (response.status == 'ok') {
						$this.parent().remove();
						if (($('.sortable-news-list').text()).replace(/\s/g, '').length == 0) {
							$('.sortable-news-list').hide(200);
						}
						toastr.success(response.message);
					}
					else {
						toastr.error(response.message);
					}
				},
				error: function(response){
					if (response.status === 422) {
						var response = response.responseJSON;
						for(var k in response.errors){
							$('#'+k).closest('.form-group').addClass('error').find('p.error-response').html(response.errors[k][0]);
						}
						return;
					}

					if (response.message) {
						toastr.error(response.message);
					}
					else {
						toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
					}
				}
			});

			return false;
		});

		$(document).on('hidden.bs.modal', '#createNewsModal', function () {
			$(this).remove();
		});

		$(document).on('click', '#createNewsBtn', function () {
			var $form = $('#ajaxCreateNewsForm');

			$('#ajaxCreateNewsForm .form-control').each(function(i, obj) {
				var $this = $(obj);
				if($this.hasClass('is-invalid')){
					$this.removeClass('is-invalid');
				}

				if($this.closest('.form-group').hasClass('error')){
					$this.closest('.form-group').removeClass('error');
				}
			});

			if ($('#select_for_news').length) {
				var select_for_news = ($('#select_for_news').selectize())[0].selectize;
			}

			$.ajax({
				url: $form.attr('action'),
				method: 'POST',
				data: $form.serialize(),
				dataType: 'json',
				success: function(response){
					if (response.status == 'ok') {
						toastr.success(response.message);
						$('#createNewsModal').modal('hide');

						let option = {
							'text': response.news.displayTitle,
							'value': response.news.id
						};

						// TODO create selectize instead of reload page on first news creation in community!
						if (!$('#select_for_news').length) {
							location.reload();
							return false;
						}

						if (response.news.is_new) {
							select_for_news.addOption(option);
						} else {
							select_for_news.updateOption(response.news.id, option);

							$('.draggable-news').each(function() {
								var $this = $(this);
								if($this.data('news_id') === response.news.id){
									$this.children('.news-title').html(response.news.displayTitle);
								}
							});
						}

						select_for_news.refreshOptions(false);
					}
					else {
						toastr.error(response.message);
					}
				},
				error: function(response){
					if (response.status === 422) {
						var response = response.responseJSON;
						for (var k in response.errors) {
							$('#'+k).closest('.form-group').addClass('error').find('p.error-response').html(response.errors[k][0]);
						}
						return;
					}

					if (response.message) {
						toastr.options.positionClass = 'toast-top-center';
						toastr.error(response.message);
						toastr.options.positionClass = 'toast-bottom-right';
					}
					else {
						toastr.options.positionClass = 'toast-top-center';
						toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
						toastr.options.positionClass = 'toast-bottom-right';
					}
				}
			})
		})
	});
})(jQuery);
