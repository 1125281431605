function setMenu() {
	var nav = $('#navbar');
	if (nav.hasClass('main')) {
		if ($(window).scrollTop() > 0) {
			nav.addClass('scrolled');
		} else {
			nav.removeClass('scrolled');
		}
	}
}

(function($) {
	"use strict";
	$(function() {

		setMenu();

		$(window).scroll(function() {
			setMenu();
		});

		$('.hamburger').on('click', function () {
			if($('.search-layer').hasClass('is-active')) {
				$('.search-icon').first().click();
			}
			$(this).toggleClass('is-active');
			$('.menu-wrapper').toggleClass('is-active');
			$('.navbar').toggleClass('menu-open');
			$('body').toggleClass('menu-open');
		});

		$('.search-icon').on('click', function () {
			var hamburger = $('.hamburger');
			if(hamburger.hasClass('is-active')) {
				hamburger.click();
			}
			$('.search-layer').toggleClass('is-active');
			$('.navbar').toggleClass('menu-open');
			$('body').toggleClass('menu-open');
		});

		//add class to menu when profile dropdown is open
		$('header .dropdown').on('show.bs.dropdown', function () {
			$('#navbar').addClass('dropdown-open');
		});
		//remove class to menu when profile dropdown is closed
		$('header .dropdown').on('hide.bs.dropdown', function () {
			$('#navbar').removeClass('dropdown-open');
		});

		$('.personal-nav .nav-link').on('click', function (e) {

			if($(this).hasClass('active')) {
				$('.personal-nav .nav-link').removeClass('active');
				$('.personal-nav .nav-link .content').fadeOut(100);
			} else {
				$('.personal-nav .nav-link').removeClass('active');
				$('.personal-nav .nav-link .content').fadeOut(100);
				$(this).addClass('active');
				$('.content', this).fadeIn(100);
			}
		});

		$('.personal-nav div.dropdown-item').on('click', function (e) {
			e.stopPropagation();
		});

		$(document).mouseup(function (e) {
			var personal_navlink = $('.personal-nav .nav-link');
			var dropdownMenu = $('.personal-nav .dropdown-menu .dropdown-item');
			if (!personal_navlink.is(e.target) && personal_navlink.has(e.target).length === 0 && !dropdownMenu.is(e.target) && dropdownMenu.has(e.target).length === 0) {
				$('.personal-nav .nav-link').removeClass('active');
			}
		});
	});
})(jQuery);