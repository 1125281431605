function enterFullScreen(element) {
	if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
		if (element.requestFullScreen) {
			element.requestFullScreen();
		} else if (element.mozRequestFullScreen) {
			element.mozRequestFullScreen();
		} else if (element.webkitRequestFullScreen) {
			element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
		} else if (element.msRequestFullscreen) {
			element.msRequestFullscreen();
		}
	}
}

function exitHandler(e)
{
	if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement)
	{
		if (!$(e.target.querySelector('.play-show')).hasClass('d-none')) {
			$('.events-show').toggleClass('d-none');
			$('header').toggleClass('d-none');
			$('.header-fix').toggleClass('d-none');
			$('.play-show').toggleClass('d-none');
		}
	}
}

(function($) {
	"use strict";

	var isHelperOn = true;

	function resizeCanvas() {
		$('#canvas .canvas_text').resizeText();
	}

	function callPlayMethod(e = null) {
		$.ajax({
			url: window.location.href,
			method: 'POST',
			datatype: 'JSON',
			data: {
				key: e != null ? e.keyCode : null
			},
			success: function (response) {
				if (response) {
					if (response.message) {
						toastr.error(response.message);
					} else if (response.texts) {
						$('#canvas .title').text(response.texts.title);
						$('#canvas .canvas_text').html(response.texts.content.replace(/\n/g, "<br/>"));
						$('#canvas .helper').text(response.texts.helper);
						if (response.play_mode = 'verse') {
							$('#canvas .canvas_text').removeClass('white-space-pre');
						} else {
							$('#canvas .canvas_text').addClass('white-space-pre');
						}
						resizeCanvas();
					} else {
						console.log(response);
					}
				}
			},
			error: function (response) {
				if (response.message) {
					toastr.error(response.message);
				} else {
					toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
				}
			}
		});
	}

	if ($('.js-play-button').length) {
		$('.js-play-button').click(function(e){
			e.preventDefault();

			$('.events-show').toggleClass('d-none');
			$('header').toggleClass('d-none');
			$('.header-fix').toggleClass('d-none');
			$('.play-show').toggleClass('d-none');

			var documentElement = document.body;
			enterFullScreen(documentElement);

			callPlayMethod();
		});
	}

	//event listener for keyup
	$(document).on('keyup', function (e) {
		// csak akkor nézzük a leütött gombokat, ha vetítő módban vagyunk
		if(e.target.querySelector('.play-show') != null && !$(e.target.querySelector('.play-show')).hasClass('d-none')) {
			// S billentyű -> ki-be kapcsolható a súgó
			if (e.keyCode == '83') {
				isHelperOn = !isHelperOn;
				$('#canvas .helper').toggleClass('hide');
				// K billentyű -> fekete betűk
			} else if (e.keyCode == '75') {
				$('#canvas').addClass('color-black');
			} else if (e.keyCode == '32') {
				$('#canvas').removeClass('color-black');
			} else {
				callPlayMethod(e);
			}
		}
	});

	if($('.play-show').length) {
		document.addEventListener('fullscreenchange', exitHandler, false);
		document.addEventListener('mozfullscreenchange', exitHandler, false);
		document.addEventListener('MSFullscreenChange', exitHandler, false);
		document.addEventListener('webkitfullscreenchange', exitHandler, false);
	}

	//maximum font méret beállítása az aktuális szöveghez
	$.fn.resizeText = function () {
		var width = $(this).innerWidth() - 40;
		var height = $(this).innerHeight() - 40;
		var newElem = $("<div>", {
			class: 'resize-box',
			html: $(this).html()
		});

		$(this).html(newElem);
		$.resizeText.increaseSize(10, 0.1, newElem, width, height);
	};

	$.resizeText = {
		increaseSize: function (increment, start, newElem, width, height) {
			var fontSize = start;

			while (newElem.outerWidth() <= width && newElem.outerHeight() <= height) {
				fontSize += increment;
				newElem.css("font-size", fontSize + "em");
			}

			if (newElem.outerWidth() > width || newElem.outerHeight() > height) {
				fontSize -= increment;
				newElem.css("font-size", fontSize + "em");
				if (increment > 0.1) {
					$.resizeText.increaseSize(increment / 10, fontSize, newElem, width, height);
				}
			}

			//maximum font méret = 7em
			if (fontSize > 7) {
				fontSize = 7;
				newElem.css("font-size", fontSize + "em");
			}
		}
	};
})(jQuery);
