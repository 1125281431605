(function($) {
	"use strict";
	$(function() {

		if ($('.js-delete-song').length) {
			$('.js-delete-song').click(function(e){
				var $this = $(this);
				e.preventDefault();

				bootbox.confirm({
					message: 'Biztosan kitörtöd a dalt?',
					buttons: {
						confirm: {
							label: 'Igen',
							className: 'btn btn-danger'
						},
						cancel: {
							label: 'Mégsem',
							className: 'btn'
						}
					},
					callback: function(r){
						if(r === true){
							$this.parent().parent().submit();
						}
					}
				});
			});
		}
	});
})(jQuery);