(function($) {
    "use strict";
    $(function() {

        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-bottom-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "5000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut",
			"closeHtml": '<button type="button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360" width="25" height="25"><circle stroke-width="18" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="180" cy="180" r="171"/><line stroke-width="18" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="117" y1="243" x2="243" y2="117"/><line stroke-width="18" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="117" y1="117" x2="243" y2="243"/></svg></button>'
        };

        if ($('.datepicker-input').length) {
			$('.datepicker-input').datepicker({
				format: 'yyyy-mm-dd',
				orientation: 'bottom',
				autoclose: true,
				clearBtn: true,
				language: 'hu',
				todayHighlight: true
			});
		}

    	objectFitImages();

		$('.js-custom-select').each(function (i, obj) {
			$(obj).selectize({
				plugins: ['typing_mode'],
				persist: false,
				usePlaceholder: true,
				create: $(obj).hasClass('enable-create-option'),
				allowEmptyOption: $(obj).hasClass('allow-empty-option'),
				render: {
					/*option: function(data, escape) {
						if ($(obj)[0].id === 'select_for_news') {
							return '<div class="option">' + data.text + '<button type="button" class="delete_news icon-delete fa fa-times ml-auto"></button></div>';
						} else {
							return '<div class="option">' + data.text + '</div>';
						}
					},*/
					option_create: function(data, escape) {
						return '<div class="create"><strong>' + escape(data.input) + '</strong> elem hozzáadása</div>';
					}
				},
				onDropdownOpen: function () {
					if ($(obj).hasClass('allow-empty-option')) {
						this.clear();
					}
				}
			});
		});

		$('.js-custom-select.selectized:not(.no-js)').each(function (i, obj) {
			var $this = $(this);
			//clear button add on document load if necessary
			if (obj.selectize.items.length !== 0) {
				$(this).parent().parent().addClass('has-clear');
				$(this).parent().append($('<span class="clear-button"/>').click(function () {
					obj.selectize.clear(true);
				}));
			}
		});

		$(".selectize-input input[placeholder]").attr("style", "width: 100%;");

		//add clear buttons with functionality for input fields
		$('input.form-control:not([readonly]):not(.nojs)').wrap('<span class="input-wrapper"/>').after($('<span class="clear-button"/>').click(function() {
			$(this).prev('input').val('').trigger('propertychange').focus();
		}));

		$('input.form-control:not([readonly]):not(.nojs)').on('input propertychange', function () {
			var $this = $(this);
			var visible = Boolean($this.val());
			$this.siblings('.clear-button').toggleClass('d-none', !visible);
		}).trigger('propertychange');

		$('.range-box:not(.no-js)').append($('<span class="clear-button"/>').click(function() {
			$(this).parent().find('input').val('').trigger('propertychange');
			$(this).parent().removeClass('focus');
		}));

		$('.range-box:not(.no-js) input').on('input propertychange', function () {
			var $this = $(this);
			var visible = Boolean($this.val() + $this.siblings('input').val());
			$this.siblings('.clear-button').toggleClass('d-none', !visible);
		}).trigger('propertychange');

		$('.range-box').on('click', function (e) {
			var $this = $(this);
			if (e.target !== $this.find('.clear-button')[0]) {
				var addFocus = false;
				if (!$this.hasClass('focus')) {
					addFocus = true;
				}
				$('.range-box').removeClass('focus');
				$this.addClass('focus');

				if (addFocus) {
					$this.children('input:first-of-type').focus();
				}
			}
		});

		$('.range-box input').on('input propertychange', function (e) {
			var $this = $(this);
			$(this).siblings('label').html($this[0].parentElement.children[1].value + ' ' + $this.siblings('span:first-of-type').text() + ' ' + $this[0].parentElement.children[3].value + ' ' + $this.siblings('span:nth-of-type(2)').text());
		});

		$(document).mouseup(function (e) {
			var rangebox = $('.range-box');
			if (!rangebox.is(e.target) && rangebox.has(e.target).length === 0) {
				rangebox.removeClass('focus');
			}
		});

		//textarea flexible height
		function ta_height_fix($ta) {
			$ta.style.height = "5px";
			$ta.style.height = ($ta.scrollHeight + 20) + "px";
		}

		$('textarea').each(function () {
			ta_height_fix(this);
		});

		$('textarea').on('keyup', function () {
			ta_height_fix(this);
		});

		$('input.form-control').on('input propertychange', function(e){
			var $this = $(this);
			if($this.hasClass('is-invalid')){
				$this.removeClass('is-invalid');
			}

			if($this.closest('.form-group').hasClass('error')){
				$this.closest('.form-group').removeClass('error');
			}
		});

		$('textarea.form-control').on('input propertychange', function(e){
			var $this = $(this);
			if($this.hasClass('is-invalid')){
				$this.removeClass('is-invalid');
			}

			if($this.closest('.form-group').hasClass('error')){
				$this.closest('.form-group').removeClass('error');
			}
		});

        if($('.file-upload-trigger').length){
            /**
             * Trigger file upload btn
             */
            $('.file-upload-trigger').click(function(e){
                e.preventDefault();

                if(e.target.className.indexOf('delete-image') != -1){
                    return;
                }
                $('#fileupload').click();
            });

            /**
             * Upload files
             */
            $('#fileupload').fileupload({
                dataType: 'json',
                type: 'POST',
                dropZone: $('.upload-block'),
                start: function(e, data){
                    $('.upload-block').addClass('d-none');
                    $('.file-loader').removeClass('d-none').addClass('d-flex');
                },
                done: function (e, data) {
                    if(data.result.status == 'error'){
                        alert(data.result.message);
                    }
                    else{
                        $('#uploaded-files').append(data.result.imageHtml);
                    }

                    $('.file-loader').removeClass('d-flex').addClass('d-none');
                    $('.upload-block').removeClass('d-none');
                }
            });
        }
    });

    /**
     * Delete upload
     */
    $(document).on('click', '.delete-upload', function(e){

        var url = $(this).data('href');

        $.ajax({
            url: url,
            method: 'POST',
            success: function(response){
                if(response.status == 'ok'){
                    window.location.reload();
                }
                else{
                    alert(response.message);
                }
            }
        });

        e.preventDefault();
    });

})(jQuery);
