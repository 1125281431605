$(document).ready(function(){

    //if enter button is pressed in login input fields -> login
    $('#loginModal input').each(function(i, obj) {
        (obj).addEventListener("keyup", function(event) {
            event.preventDefault();
            if (event.keyCode === 13) {
                $('#loginBtn').click();
            }
        });
    });

    $('#loginBtn').click(function(){
        var $form = $('#ajaxLoginForm');
        var redirectTo = $form.find('#redirectTo').val().trim();

        $.ajax({
            url: $form.attr('action'),
            method: 'POST',
            data: $form.serialize(),
            success: function(response){
                if (response.status == 'ok') {
                    if (redirectTo != '') {
                        window.location.replace(redirectTo);
                    }
                    else {
                        window.location.reload();
                    }

                }
                else {
					toastr.options.positionClass = 'toast-top-center';
                    toastr.error(response.message);
					toastr.options.positionClass = 'toast-bottom-right';
                }
            },
            error: function(response){
                console.log(response);
                if (response.message) {
                    toastr.options.positionClass = 'toast-top-center';
					toastr.error(response.message);
					toastr.options.positionClass = 'toast-bottom-right';
                }
                else {
					toastr.options.positionClass = 'toast-top-center';
                    toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
					toastr.options.positionClass = 'toast-bottom-right';
                }
            }
        })
    });

    $('#communitySelectorBtn').click(function(){
        var $form = $('#ajaxCommunitySelectorForm');
        var redirectTo = $form.find('#redirectTo').val().trim();

        $.ajax({
            url: $form.attr('action'),
            method: 'POST',
            data: $form.serialize(),
            success: function(response){
                if (response.status == 'ok') {
                    if (redirectTo != '') {
                        window.location.replace(redirectTo);
                    }
                    else {
                        window.location.reload();
                    }

                }
                else {
					toastr.options.positionClass = 'toast-top-center';
                    toastr.error(response.message);
					toastr.options.positionClass = 'toast-bottom-right';
                }
            },
            error: function(response) {
                console.log(response);
                if (response.message) {
                    toastr.options.positionClass = 'toast-top-center';
					toastr.error(response.message);
					toastr.options.positionClass = 'toast-bottom-right';
                }
                else {
					toastr.options.positionClass = 'toast-top-center';
                    toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
					toastr.options.positionClass = 'toast-bottom-right';
                }
            }
        })
    });

    $('#regBtn').click(function(e){
        var $form = $('#ajaxRegForm');

        e.preventDefault();

        $.ajax({
            url: $form.attr('action'),
            type: 'POST',
            data: $form.serialize(),
            dataType: 'json',
            success: function(response){
                if (response.status == 'ok') {
					toastr.options.positionClass = 'toast-top-center';
					toastr.success(response.message);
					toastr.options.positionClass = 'toast-bottom-right';
                    $('#regModal').modal('hide');
                }
                else {
                    if (response.errors){
                        for (var k in response.errors) {
                            $('#'+k).parent().addClass('error');
                        }
                    }
                    else {
                        for (var k in response.message) {
                            $('#'+k).parent().addClass('error');
                        }
                    }
                }
            },
            error: function(response){
                if (response.status === 422) {
                    var response = response.responseJSON;
                    for (var k in response.errors) {
                        $('#'+k).closest('.form-group').addClass('error').find('p.error-response').html(response.errors[k][0]);
                    }
                    return;
                }

                if (response.message) {
					toastr.options.positionClass = 'toast-top-center';
					toastr.error(response.message);
					toastr.options.positionClass = 'toast-bottom-right';
                }
                else {
					toastr.options.positionClass = 'toast-top-center';
					toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
					toastr.options.positionClass = 'toast-bottom-right';
                }
            }
        })
    });

    $('#ajaxRegForm input').on('keyup', function(){
        var $this = $(this);
        if ($this.parent().hasClass('error') && $this.val().trim() != '') {
            $this.parent().removeClass('error');
        }
    });

    $('#community_change').on('change', function (e) {
        var $form = $('#community_change_form');
        $form.submit();
    });

    $('.role_change').on('change', function (e) {
        var $form = $(this).parents('form:first');
        $form.submit();
    });

    if ($('.js-delete-user').length) {
        $('.js-delete-user').click(function(e){
            var $this = $(this);
            e.preventDefault();

            bootbox.confirm({
                message: 'Biztosan kitörtöd a felhasználót?',
                buttons: {
                    confirm: {
                        label: 'Igen',
                        className: 'btn btn-danger'
                    },
                    cancel: {
                        label: 'Mégsem',
                        className: 'btn'
                    }
                },
                callback: function(r){
                    if(r === true){
                        $this.parent().submit();
                    }
                }
            });
        });
    }
});