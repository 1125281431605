(function($) {
	"use strict";
	$(function() {
		$(document).on('click', '.js-delete-news', function(e){
			var $this = $(this);
			e.preventDefault();

			bootbox.confirm({
				message: 'Biztosan kitörtöd a hírt?',
				buttons: {
					confirm: {
						label: 'Igen',
						className: 'btn btn-danger'
					},
					cancel: {
						label: 'Mégsem',
						className: 'btn'
					}
				},
				callback: function(r){
					if(r === true){
						$this.parent().submit();
					}
				}
			});
		});
	});
})(jQuery);